<template>
  <a-modal style="top: 8px" width="80%" v-model="visible" :footer="null" :title="'经销商还款'" :maskClosable="false">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="流水单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="创建人">
            <a-input v-model="searchData.dealNo" placeholder="请输入创建人"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="金额类型">
            <!-- <DictSelect
              field="amountType"
              :value.sync="searchData.amountType"
              style="width: 100%"
              placeholder="请选择金额状态"
            ></DictSelect> -->
            <a-select :default-value="1">
              <a-select-option :value="1"> 全部商品 </a-select-option>
              <a-select-option :value="2"> 指定类型 </a-select-option>
              <a-select-option :value="3"> 指定商品 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="状态">
            <a-select :default-value="1">
              <a-select-option :value="1"> 逾期 </a-select-option>
              <a-select-option :value="2"> 正常 </a-select-option>
              <a-select-option :value="3"> 即将到期 </a-select-option>
              <a-select-option :value="4"> 已过期 </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="有效时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row type="flex" justify="end">
      <a-col :xxl="2">
        <a-button type="primary" @click="handlerAllStill">全部还款</a-button>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
      :pagination="false"
    >
      <span slot="HKStatus" slot-scope="text">
        <a-tag color="#ffc000" v-if="text == 1">逾期</a-tag>
        <a-tag color="#92d050" v-if="text == 2">正常</a-tag>
        <a-tag color="#ff0000" v-if="text == 3">即将到期</a-tag>
        <a-tag color="#808080" v-if="text == 4">已过期</a-tag>
      </span>
      <span slot="amountType" slot-scope="text">
        <span v-if="text == 1"><a-tag color="green"> 全部商品 </a-tag></span>
        <span v-if="text == 2"><a-tag color="cyan"> 分类商品 </a-tag></span>
        <span v-if="text == 3"><a-tag color="blue"> 指定商品 </a-tag></span>
      </span>
      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealNo" slot-scope="text">
        <a @click="checkSingleInfo()">{{ text }}</a>
      </span>
      <span slot="HKJE" slot-scope="text, row">
        <a-input-number id="inputNumber" v-model="row.dealType" />
      </span>
    </a-table>

    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
      <a-button type="primary" @click="toSubmit(tableData)">确认</a-button>
    </div>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
    <FinanceReceiptInfoModal ref="FinanceReceiptInfoModal"></FinanceReceiptInfoModal>
    <SingleRepaymentInfoModal ref="SingleRepaymentInfoModal"></SingleRepaymentInfoModal>
  </a-modal>
</template>

<script>
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
import FinanceReceiptInfoModal from '@/views/finance_receipt/components/FinanceReceiptInfoModal'
//
import SingleRepaymentInfoModal from './SingleRepaymentInfoModal'
const qs = require('qs')

export default {
  name: 'RepaymentModal',
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
    FinanceReceiptInfoModal,
    SingleRepaymentInfoModal,
  },
  data() {
    return {
      columns: [
        {
          title: '流水单号',
          dataIndex: 'dealNo',
          align: 'center',
          key: 'dealNo',
          scopedSlots: { customRender: 'dealNo' },
        },
        {
          title: '额度名称',
          dataIndex: 'dealDate',
          align: 'center',
          key: 'dealDate',
        },
        {
          title: '额度',
          dataIndex: 'ED',
          align: 'ED',
          key: 'amount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '已使用额度',
          dataIndex: 'YSYED',
          align: 'center',
          key: 'YSYED',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '欠款额度',
          dataIndex: 'QKED',
          align: 'center',
          key: 'QKED',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '金额类型',
          dataIndex: 'amountType',
          align: 'center',
          key: 'amountType',
          scopedSlots: { customRender: 'amountType' },
        },
        {
          title: '有效时间',
          dataIndex: 'YXTime',
          key: 'YXTime',
          align: 'center',
          width: 150,
        },
        {
          title: '状态',
          dataIndex: 'HKStatus',
          key: 'HKStatus',
          width: 150,
          align: 'center',
          scopedSlots: { customRender: 'HKStatus' },
        },
        {
          title: '创建人',
          dataIndex: 'CJR',
          key: 'CJR',
          align: 'center',
          width: 150,
        },
        {
          title: '创建时间',
          dataIndex: 'CJSJ',
          key: 'CJSJ',
          align: 'center',
          width: 250,
        },
        {
          title: '还款金额',
          dataIndex: 'HKJE',
          align: 'center',
          key: 'HKJE',
          scopedSlots: { customRender: 'HKJE' },
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
    }
  },
  methods: {
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },
    // 全部还款
    handlerAllStill() {
      this.$confirm({
        title: '是否全部还款?',
        // content: 'When clicked the OK button, this dialog will be closed after 1 second',
        onOk() {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          }).catch(() => console.log('Oops errors!'))
        },
        onCancel() {},
      })
    },
    // 提交
    toSubmit(data) {
      console.log(JSON.parse(JSON.stringify(data)))
    },
    // 打开账单流水数据

    checkSingleInfo() {
      this.$refs.SingleRepaymentInfoModal.isShowByOrderCode('check')
    },
    //打开上游单据
    checkOrderInfo(orderCode, dealType) {
      console.log(orderCode, dealType)
      const data = {
        orderCode: orderCode,
      }
      if (dealType == 3) {
        this.$refs.FinanceReceiptInfoModal.setRowDataByCode(orderCode, 'check')
      } else {
        if (orderCode.indexOf('DO') !== -1) {
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        } else {
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },
    setRowData(row) {
      this.visible = true
      this.dealerId = row.dealerId
      this.title = row.dealerName
      this.getData()
    },

    getData() {
      let params = {
        dealerId: this.dealerId,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      this.axios
        .get(
          `/api/dealer/dealer/dealerPayRecord/selectPayRecordByDealer?${qs.stringify(postData, {
            arrayFormat: 'repeat',
          })}`
        )
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            // this.tableData = records
            this.tableData = [
              {
                dealNo: '0000000000',
                amount: 500,
                amountType: '1',
                YXTime: '2022-7-1',
                HKStatus: '1',
                ED: 500,
                YSYED: 500,
                QKED: 500,
                CJR: '马化腾',
                CJSJ: '2022-07-01 :00:00:00',
                HKJE: 500,
              },
              {
                dealNo: '0000000000',
                amount: 500,
                amountType: '1',
                YXTime: '2022-7-1',
                HKStatus: '2',
                ED: 500,
                YSYED: 500,
                QKED: 500,
                CJR: '马化腾',
                CJSJ: '2022-07-01 :00:00:00',
                HKJE: 500,
              },
              {
                dealNo: '0000000000',
                amount: 500,
                amountType: '1',
                YXTime: '2022-7-1',
                HKStatus: '3',
                ED: 500,
                YSYED: 500,
                QKED: 500,
                CJR: '马化腾',
                CJSJ: '2022-07-01 :00:00:00',
                HKJE: 500,
              },
            ]
          }
        })
    },
    toCancel() {
      this.visible = false
    },
  },
}
</script>

<style scoped>
</style>